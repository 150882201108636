import Image from 'next/legacy/image'
import NextLink from 'next/link'
import { styled } from '@/src/stitches.config'
import StarRating from '../reviews/StarRating'
import LinkButton from '@/src/common/components/marketing/buttons/LinkButton'

const gradientColor1 = '#9A30F5'
const gradientColor2 = '#3D7CF6'

export interface ProductCardProps {
    productImage: string
    imagePosition?: string
    productName: string
    productDescription: string
    totalReviews?: number
    starRating?: number
    showButton: boolean
    buttonText?: string
    buttonVariant?: 'primary' | 'primaryOutline' | 'secondary'
    newProduct: boolean
    productUrl?: string
    calloutText?: string
    calloutTextSize?: string
    starColor?: string
    unfilledStarColor?: string
    starSize?: number
    altText?: string
}

const BadgeWrapper = styled('div', {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    left: '9%',
    top: '-6%',
    '@media (min-width: 991px) and (max-width: 3000px)': {
        left: '12%',
    },
})

const RatingBox = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '.5rem',
    margin: '.15rem .5rem',
})

const Wrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    margin: '2rem 1.25rem 1rem',
    maxWidth: '250px',
    img: {
        borderRadius: '10px',
        objectFit: 'cover',
    },
})

const Badge = styled('div', {
    height: '4.25rem',
    width: '4.25rem',
    lineHeight: '.95rem',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: 800,
    color: '#FFFFFF',
    background: `linear-gradient(44.53deg, ${gradientColor1 ?? ''} 11.47%, ${
        gradientColor2 ?? ''
    } 89.19%)`,
    borderRadius: '100%',
})

const ProductName = styled('p', {
    width: '90%',
    fontSize: '$5',
    fontWeight: 600,
    mt: '20px',
    mb: 0,
    textAlign: 'center',
})

const ProductDescription = styled('p', {
    fontSize: '$3',
    textAlign: 'center',
    maxWidth: '90%',
    lineHeight: '1.5rem',
    marginBottom: '.75rem',
})

const ReviewText = styled('p', {
    fontSize: '$2',
})

const ProductCard = ({
    productImage,
    imagePosition,
    productName,
    productDescription,
    totalReviews,
    newProduct,
    productUrl,
    calloutText,
    calloutTextSize,
    altText,
    starColor,
    unfilledStarColor,
    starSize,
    starRating = 0,
    showButton = false,
    buttonText = 'Buy now',
    buttonVariant = 'primaryOutline',
}: ProductCardProps) => {
    const sharedImageStyles = {
        objectPosition: imagePosition ?? '',
    }
    return (
        <Wrapper>
            {newProduct && (
                <BadgeWrapper>
                    <Badge
                        style={{
                            fontSize: calloutTextSize ?? '',
                        }}
                    >
                        {calloutText}
                    </Badge>
                </BadgeWrapper>
            )}
            {productUrl ? (
                <NextLink href={productUrl} style={{ display: 'flex' }}>
                    <Image
                        src={productImage}
                        height={250}
                        width={250}
                        alt={altText ?? 'product image'}
                        style={{
                            ...sharedImageStyles,
                            cursor: 'pointer',
                        }}
                    />
                </NextLink>
            ) : (
                <Image
                    src={productImage}
                    height={250}
                    width={250}
                    alt={altText ?? 'product image'}
                    style={sharedImageStyles}
                />
            )}
            <ProductName>{productName}</ProductName>
            <ProductDescription>{productDescription}</ProductDescription>
            {starRating > 0 && (
                <RatingBox>
                    <StarRating
                        ratingValue={starRating}
                        starSize={starSize ?? 20}
                        emptyColor={unfilledStarColor ?? '$lightest'}
                        fillColor={starColor ?? '$dark'}
                    />
                    {totalReviews && <ReviewText>({totalReviews})</ReviewText>}
                </RatingBox>
            )}
            {showButton && (
                <LinkButton
                    url={productUrl ?? ''}
                    label={buttonText}
                    variant={buttonVariant}
                    textTransform={'uppercase'}
                    size={'sm'}
                    showArrow
                    buttonWidth={'90%'}
                />
            )}
        </Wrapper>
    )
}

export default ProductCard
